





















import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import StyleSubjectBackground from '@/components/atoms/StyleSubjectBackground.vue'
import LoadEnableSubjectsApi from '@/mixins/utils/LoadEnableSubjectsApi'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import DrillWebApi from '@/mixins/drillsv3/WebApi'
import DrillTransition from '@/mixins/drillsv3/DrillTransition'

@Component({
  components: {
    SidebarSwitcher,
    ColoredBox,
    ButtonBase,
  },
})
export default class SelectSubject extends Mixins(
  StyleSubjectBackground,
  LoadEnableSubjectsApi,
  ClassModeMethods,
  DrillWebApi,
  DrillTransition,
) {
  public async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.resetCurriculumSUnitsGrade()
    await this.loadEnableSubjects()
    Vue.prototype.$loading.complete()
  }

  private subjectDatas: { id: number; text: string; code: string }[] = []
  private studyLogicType = 'AI'

  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private classMode = this.$route.params.classMode

  @Watch('$route')
  async onChangeRoute(): Promise<void> {
    this.classMode = this.$route.params.classMode
    location.reload()
  }

  /**
   * 教科選択
   */
  private async selectSubject(subjectCode: string, subjectId: number): Promise<any> {
    // 項目選択画面へ遷移
    await this.setSubjectCode(subjectCode)
    if (this.$route.params.classMode !== 'NY') {
      if (this.$store.getters['drillsV3/modeActive'].is_random_question === true){
        this.startDrill(subjectId) // 定
      } else {
        this.$router.push(`/student/v3/curriculum-s-units/${this.classMode}/${subjectCode}`)
      }
    } else {
      this.$router.push(`/student/entrance-range-selection`) // 入試
    }
  }

  /**
   * 教科コードのcookieセット
   */
  private async setSubjectCode(subjectCode: string) {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['subjectCode'] = subjectCode
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  /**
   * サービス設定で有効な教科を取得し、教科プルダウンに反映
   */
  private async loadEnableSubjects() {
    const enableSubjects = await this.loadGdlsSubjectsApiV3(this.branchId, this.userId, this.classMode)

    this.subjectDatas = enableSubjects.map((subject) => {
      return { id: subject.id, text: subject.name, code: subject.code }
    })
  }

  /**
   * 演習開始
  */
  private async startDrill(subjectId: number) {
    Vue.prototype.$loading.start()

    // ドリル開始APIを実行
    const drill = await this.startDrillApiRandom(this.studyLogicType, this.classMode, 'EN', subjectId, {
      curriculumSUnitId: 444
    })

    Vue.prototype.$loading.complete()
    // 演習画面へ遷移
    this.$router.push({
      path: this.urlDrillWithId(drill.resultDrillId),
    })
  }

  /**
   * 項目リストの学年選択情報をリセットする
   */
  private async resetCurriculumSUnitsGrade() {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    delete cookie['curriculumSUnitsGrade']
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  private get classModeName() {
    let name = ''
    if (this.$store.getters['drillsV3/modeActive']) {
      name = this.$store.getters['drillsV3/modeActive'].name
    }
    return name
  }
}
